//@import '../custom-materialize';
@import './fonts';

body {
  margin: 0;
  padding: 0;
  background-color: #FEF1EB;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'quicksans', 'roboto', sans-serif;
}

p {
  font-family: 'dtl_documentast', 'Times', serif;
  font-size: 13pt;
}

th, td {
  padding: 15px;
}

label {
  color: black;
}
