.modal {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 900px;
    height: 515px;
    background: #FFFDFB;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
}

.closeIcon {
    color: #757575;
    cursor: pointer;
    font-size: 18px;
    align-self: flex-end;
    padding: 10px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(51, 51, 51, 0.9);
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Futura;
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    color: #525252;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-top: 24px;
}

.message {
    font-family: Futura;
    color: #525252;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 24px 96px 42px 96px;
    max-width: 480px;
}

.button {
    display: block;
    align-self: center;
    width: 130px;
    height: 40px;
    color: #FFFDFB;
    background-color: #4C4C4C;
    text-transform: uppercase;
    border-radius: 4px;
    font-family: Futura;
    font-size: 10px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
}

.button:hover, .button:focus {
    background-color: #333333;
}

.image {
    max-height: 200px;
}