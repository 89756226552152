.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card {
    background: #FFFFFF;
    font-family: Futura;
    margin: 60px 0;
    padding: 60px 100px;
    min-width: 700px;
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.form > div {
    margin: 12px 0;
}

.message {
    font-family: Futura;
    color: #525252;
    font-size: 14px;
    line-height: 20px;
    max-width: 480px;
}

.button {
    display: block;
    align-self: center;
    width: 130px;
    height: 40px;
    color: #FFFDFB;
    background-color: #4C4C4C;
    text-transform: uppercase;
    border-radius: 4px;
    font-family: Futura;
    font-size: 10px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 1px;
    border: none;
    cursor: pointer;
    margin-top: 60px;
}

.button:hover,
.button:focus {
    background-color: #333333;
}

.error {
    border: 1px solid #FC6457;
    background-color: rgba(252, 100, 87, 0.05);
    color: #FC6457;
    font-size: 12px;
    padding: 16px;
}