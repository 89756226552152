.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card {
    background: #FFFFFF;
    font-family: Futura;
    margin: 60px 0;
    padding: 60px 100px;
    min-width: 700px;
    display: flex;
    flex-direction: column;
}

.title {
    font-family: Futura;
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    color: #525252;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-top: 24px;
}

.message {
    font-family: Futura;
    color: #525252;
    font-size: 14px;
    line-height: 20px;
    max-width: 480px;
}

.button {
    display: block;
    align-self: center;
    width: 130px;
    height: 40px;
    color: #FFFDFB;
    background-color: #4C4C4C;
    text-transform: uppercase;
    border-radius: 4px;
    font-family: Futura;
    font-size: 10px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 1px;
    border: none;
    margin-top: 20px;
    pointer: cursor;
}

